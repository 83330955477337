.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}

.mainContainerContent {
  display: flex;
  /* align-items: flex-start; */
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  gap: 10px;
  height: 100%;
  align-items: stretch;
}

.pricingBoxContainer {
  /* width: 600px; */
  min-height: 845px;
  width: 30%;
  /* height: 100%; */
  border-radius: 10px;
  background-color: rgb(246, 249, 248);

  border-right: 2px solid rgb(221, 221, 221);
  border-bottom: 2px solid rgb(221, 221, 221);

  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.nonGroupPricingBoxContainer {
  /* min-height: 845px; */
  border-radius: 50px;
  background-color: rgb(246, 249, 248);
  /* border: 4px solid; */
  /* border-image: linear-gradient(
    to right,
    #5de0e6 0%,
    #004aad 50%,
    #1857ab 100%
  );
  border-image-slice: 1; */
  padding-top: 20px;
  padding-bottom: 20px;
  /* margin-bottom: 30px; */
  /* margin-top: 30px; */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

.nonGroupPricingSmallBoxContainer {
  /* min-height: 400px; */
  border-radius: 50px;
  background-color: rgb(246, 249, 248);
  /* border: 4px solid; */
  /* border-image: linear-gradient(
    to right,
    #5de0e6 0%,
    #004aad 50%,
    #1857ab 100%
  );
  border-image-slice: 1; */
  padding-top: 20px;
  padding-bottom: 20px;
  /* margin-bottom: 30px; */
  /* margin-top: 30px; */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

.headerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.correctIconFeatureContainer {
  background-color: #00f48e;
  border-radius: 50%;
}

.userBadgeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  border-radius: 30px;

  background: linear-gradient(180deg,
      rgb(174, 134, 37) 0%,
      rgb(247, 239, 138) 30%,
      rgb(210, 172, 71) 58%,
      rgb(237, 201, 103) 100%);

  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
  width: 160px;
  height: 44px;
}

.userBadgeText {
  font-size: 25px;
  font-family: 'Lato';
  color: rgb(0, 0, 0);
  font-weight: 600;
  line-height: 1.997;
}

.packageName {
  /* font-size: 33.333px; */
  font-size: 28px;
  font-family: 'Lato';
  color: rgb(0, 130, 194);
  font-weight: 600;
}

.packageTag {
  background: linear-gradient(180deg, rgb(0, 206, 82) 0%, rgb(4, 232, 4) 100%);
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
  width: 162px;
  height: 44px;
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  /* font-size: 33.383px; */
  font-size: 25px;
  font-family: 'Lato';
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.packageTagPro {
  background: linear-gradient(180deg,
      rgb(174, 134, 37) 0%,
      rgb(247, 239, 138) 30%,
      rgb(210, 172, 71) 58%,
      rgb(237, 201, 103) 100%);
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
  width: 162px;
  height: 44px;
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  /* font-size: 33.383px; */
  font-size: 25px;
  font-family: 'Lato';
  color: rgb(0, 0, 0);
  font-weight: 600;
}

.featuresContainer {
  margin-top: 30px;
  /* margin-bottom: 20px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.extraProfeatureContainer {
  max-width: 100%;
  background: linear-gradient(90deg, #fffff0 0%, #ecc00c 100%);
  /* background-clip: padding-box; */
  /* margin: 4px; */
  height: auto;
  margin: 2px !important;
  padding: 6px;
  /* margin-top: 30px; */
  /* border: 4px solid; */

  /* border-image-slice: 1; */
  border-radius: 25px;
  position: relative;
}

/* .extraProfeatureContainer::after {
  position: absolute;
  top: -4px;
  bottom: -4px;
  right: -4px;
  left: -4px;
  background: linear-gradient(to right, #5de0e6 0%, #004aad 50%, #1857ab 100%);
  content: '';
  z-index: -1;
  border-radius: 25px;
} */

.featureText {
  font-size: 15px;
  font-family: 'Lato';
  color: rgb(114, 114, 114);
  line-height: 1.4;
  margin-bottom: 5px;
}

.limitedProFeatureText {
  /* font-size: 18px; */
  font-family: 'Lato';
  color: #1893f8;
  line-height: 1.4;
  margin-bottom: 5px;
}

.bottomContainerFree {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 60px;
}

.bottomContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  /* margin-top: -30px; */
}

.learnMoreIcon {
  width: 56px;
  height: 36px;
}

.learnMoreBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  border: 1px solid black;
  cursor: pointer;
}

.learnMoreBtn:hover {
  background-color: rgb(239, 239, 239);
  border: 1px solid rgb(8, 138, 198);
}

.learnMoreText {
  font-size: 21px;
  font-family: 'Lato';
  color: rgb(8, 138, 198);
  line-height: 1.6;
}

.pricingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  flex-direction: row;
}

.priceBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.priceBtnLabel {
  font-size: 21px;
  font-family: 'Lato';
  /* color: rgb(0, 206, 82); */
  color: rgb(0, 130, 194);
  line-height: 1.2;
  font-weight: bold;
}

.priceBtn {
  border-radius: 5px;
  background-color: rgb(0, 206, 82);
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
  width: 163px;
  height: 45px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 25px;
  font-family: 'Lato';
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 2.667;
}

.featuresSubtitle {
  font-size: 25px;
  font-family: 'Lato';
  color: rgb(119, 123, 125);
  line-height: 1.2;
  margin-top: 20px;
  margin-bottom: 5px;
}

.featuresPlusSign {
  font-size: 34px;
  font-family: 'Lato';
  color: rgb(0, 130, 194);
  font-weight: bold;
}

.featuresSubtitleCharge {
  font-size: 30px;
  font-family: 'Lato';
  color: rgb(0, 206, 82);
  line-height: 1.2;
  font-weight: bolder;
  margin-top: 30px;
  /* margin-bottom: 30px; */
}

.featuresContainerPro1 {
  margin-top: 30px;
}

.bottomContainerPro1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 120px;
}

@media only screen and (max-width: 1420px) {
  .bottomContainer {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 1365px) {

  .bottomContainer,
  .bottomContainerPro1 {
    flex-direction: column-reverse;
    align-items: center;
    gap: 40px;
    margin-top: 20px;
  }

  .learnMoreBtn {
    width: 60%;
    justify-content: space-between;
  }

  .featuresSubtitleCharge {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .featuresSubtitle {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 1110px) {
  .learnMoreBtn {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .mainContainerContent {
    flex-direction: column;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    margin: 0%;
    gap: 5px;
  }

  .pricingBoxContainer {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    display: inherit;
    min-height: auto;
    padding: 5px;
  }

  .bottomContainer,
  .bottomContainerPro1 {
    flex-direction: row;
    align-items: flex-end;
    gap: 0px;
    margin-top: 0px;
  }

  .learnMoreBtn {
    width: 30%;
    justify-content: space-between;
  }

  .featuresSubtitleCharge {
    margin-top: 30px;
    /* margin-bottom: 30px; */
  }

  .featuresSubtitle {
    margin-bottom: 5px;
  }

  .featuresContainer {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 670px) {
  .learnMoreBtn {
    width: 40%;
  }
}

@media only screen and (max-width: 515px) {

  .bottomContainer,
  .bottomContainerPro1 {
    flex-direction: column-reverse;
    align-items: center;
    /* gap: 40px;
        margin-top: 20px;*/
  }

  .learnMoreBtn {
    width: 95%;
    justify-content: space-between;
  }

  .featureText {
    font-size: 15px;
  }

  .userBadgeContainer,
  .packageTagPro,
  .packageTag {
    width: 140px;
    height: 37px;
    font-size: 20px;
  }

  .userBadgeText {
    font-size: 20px;
  }

  .userBadgeContainer>img {
    height: 25px;
  }

  .featuresContainerPro1 {
    margin-top: 0px;
  }

  .priceBtn {
    font-size: 18px;
    height: 32px;
    width: 120px;
  }

  .priceBtnLabel {
    font-size: 18px;
  }
}

@media only screen and (max-width: 360px) {
  .pricingBoxContainer {
    padding-left: 10px;
    padding-right: 10px;
    min-height: 0px;
    display: inherit;
    min-height: auto;
    width: 94%;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .pricingContainer {
    flex-direction: row;
  }
}

.activeText {
  color: #ffffff !important;
}

.inActivetext {
  padding-left: 29px;
  color: #83d2e8 !important;
}

.toggleBtnRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-right: 4%;
  margin-top: 10px;
}

.toggleLabel {
  font-size: 20px;
  font-family: 'Lato';
  color: rgb(115, 115, 115);
  font-weight: bold;
  line-height: 1.2;
}

.toggleBtn {
  margin-left: 10px;
  margin-right: 10px;
}

.gradientLockLabel {
  background: linear-gradient(270deg, #004AAD, #5DE0E6);
  color: #FFFFFF;
  border: unset;
  padding: 0.5rem 1.5rem;
  margin-top:1.5rem;
  border-radius: 20px;
}

.planFeatureBorder {
  border: 4px solid transparent;
  border-radius: 20px;
  background: 
      linear-gradient(to bottom, white, white), 
      linear-gradient(to bottom,  #3acfd5 0%, #3a4ed5 100%); 
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;

  padding: 20px;
  width: 80%
}

.planFeatureHeading {
  text-align: center;
  color: var(--blue-color);
  font-size: 1.7rem;
  font-weight: 400;
}

.fiveStar {
  background-color: #ECECEC; 
  border-radius: 10px; 
  margin-top: -40px;
  margin-left: 10%;
  margin-right: 10%;
}

.priceVideoThumbnailContainer {
  position: relative;
  display: inline-block;
  width: 250px; /* Set the desired width */
  height: 150px; /* Set the desired height */
  border-radius: 15px; /* Rounded corners */
  overflow: hidden;
}

.priceVideoThumbnailContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.priceVideoPlayBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 15px 20px;
  border-radius: 50%;
  color: white;
  cursor: pointer;
}

.priceVideoPlayBtn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.priceVideoTitle {
  color: black;
  font-size: 2.5rem;
  font-weight: 500;
}

.priceVideoTitle span{
  color: white;
  background-color: rgb(8, 138, 198);
  
}

.priceVideoDivider {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 50px;
  margin-right: 50px;
  padding: 0.5px;
  border: none;  
  width: 80%;
  background-color: #ffdf2b;
}

@media screen and (max-width: 1024px) {
  .fiveStar {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media screen and (max-width: 540px) {
  .fiveStar {
    margin-left: 0;
    margin-right: 0;
  }
}