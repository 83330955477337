.dashboardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  width: 100%;
}

.dashboardContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  width: 95%;
  height: 100%;
}

.userBadgeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  cursor: pointer;
  border-radius: 30px;

  background: linear-gradient(180deg,
      rgb(174, 134, 37) 0%,
      rgb(247, 239, 138) 30%,
      rgb(210, 172, 71) 58%,
      rgb(237, 201, 103) 100%);

  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.35);
  width: 142px;
  height: 40px;
  margin-bottom: 20px;
}

.userBadgeText {
  font-size: 20px;
  font-family: 'Lato';
  color: rgb(0, 0, 0);
  font-weight: bold;
  line-height: 1.997;
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  /* margin-left: 20px;
  margin-right: 20px; */
  width: 100%;
}

.mainContentHeader {
  width: 100%;
}

.userNameHeader {
  font-size: 33.333px;
  font-family: 'Lato';
  line-height: 1.2;
  font-weight: 600;
}

.centerClass {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchBarContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  border-width: 1.1px;
  border-color: rgb(8, 138, 199);
  border-style: solid;
  border-radius: 9px;
  background-color: rgb(255, 255, 255);
  width: 974.8px;
  /* height: 45.8px; */

  /* Shadow/xs */
  /* box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); */
}

.searchBarIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 47px;
  background-color: #dee5ed;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.searchIcon {
  width: 30px;
  height: 30px;
  color: black;
}

.searchInput {
  width: 100%;
  height: 40px;
  border: none;
  margin-top: 4px;
  font-size: 21px;
  font-family: 'Lato';
  color: rgb(8, 138, 199);
  line-height: 1.2;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #dee5ed00;
}

.searchInput:focus {
  outline: none;
}

.searchInput::placeholder {
  font-size: 21px;
  font-family: 'Lato';
  color: rgb(142, 142, 142);
  line-height: 1.2;
}

.mainContentBody {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.heading {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  line-height: 1.2;
  text-align: left;
}

.activityContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  /* width: 100%;  */
  width: 796px;
}

.activityContainerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
}

.downloadCSVBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  /* width: 134px; */
  height: 43px;

  font: normal normal bold 14px 'Lato';
  /* border: 1px solid rgb(148, 153, 155); */
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;

  box-shadow: 1px 2px 4px 1px rgba(43, 43, 43, 0.35);
  padding-left: 12px;
  padding-right: 12px;
}

.downloadIcon {
  width: 22px;
  height: 22px;
  color: rgb(8, 138, 199);
}

.activityFilterDropDown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 134px;
  height: 43px;

  font: normal normal bold 14px 'Lato';
  /* border: 1px solid rgb(148, 153, 155); */
  border-radius: 8px;
  background-color: #FFFFFF;
  cursor: pointer;

  box-shadow: 1px 2px 4px 1px rgba(43, 43, 43, 0.35);
  padding-left: 12px;
  padding-right: 12px;
}

.arrowDown {
  width: 20px;
  height: 20px;
}

.calender {
  width: 50px;
  height: 50px;
  margin-top: 5px;
}

.activityCardsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 24px 16px;
  width: 100%;
  justify-content: center;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  border-radius: 10px;
  background: #003B53;

  /* Shadow/SM */
  box-shadow: 0px 2px 3px 1px #55D6E0;
}

.cardContainerSelected {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  border: 2px solid #f58227;
  border-radius: 10px;
  background: #fff;

  /* Shadow/SM */
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px -1px rgba(16, 24, 40, 0.1);
}

.imageContainer {
  /* width: 242px; */
  height: 80px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  padding: 10px;
  border-radius: 8px;
  /* background: var(--neutral-300, #f7f7f8); */
}

.cardBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(8, 138, 199);
  padding: 15px;
  width: 83%;

  border-radius: 8px;
}

.cardBtnText {
  font-family: 'Lato';
  font-size: 18px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  text-align: center;
}

.resultscontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  /* width: 100%;  */
  width: 513px;
}

.resultsContainerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 4px;
  border-radius: 8px;
}

.activityBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  font-size: '1.2rem !important';
  font-weight: 500 !important;
  color: #303336;
  text-align: center;
}

.resultsListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px;


}

.itemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f2f2f2;

  box-shadow: 0px 4px 7px rgba(16, 24, 40, 0.05);
}

.subitemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 1.8px;
  border-radius: 6px;

  box-shadow: 0px 4px 7px rgba(16, 24, 40, 0.05);
}

.knoSmilie {
  height: 50px;
  border-radius: 30px;
  width: 50px;
  object-fit: cover;
  margin-right: 6px;
}

.muiIconSize {
  font-size: 2rem !important;
  color: rgb(8, 138, 199);
}

.flutterIconSize {
  font-size: 1.1rem !important;
  color: rgb(8, 138, 199);
}

.itemContainerContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
  margin-left: 10px;
  margin-right: 10px;
}

.itemContainerContentHeader {


  text-align: left;
  white-space: pre-line;
  color: #303336;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
}

.subItemContainerContentHeader {
  font-family: 'Lato';
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  white-space: pre-line;
  color: #042A43;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
}

.itemContainerContentBody {
  font-family: 'Lato';
  font-size: 12px;
  font-weight: bold;
  color: #D2D743;
}

.subItemContainerContentBody {
  font-family: 'Lato';
  font-size: 12px;
  font-weight: bold;
  color: #042A43;
}

.itemContainerContentBody2 {
  font-family: 'Lato';
  font-size: 10px;
  /* font-weight: bold; */
  /* color: rgb(8, 138, 199); */
}

.chevronDown {
  width: 20px;
  height: 20px;
}

.businessPage {
  width: 30px;
}

.leaderCornerBG {
  margin: 0;
  width: 100%;
  padding-top: 30px;
    padding-bottom: 30px;
  /* height: 90vh; */
  background-image: url("../../public/static/images/leaders_corner_background.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leaderCornerBGOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(8, 138, 199);
  opacity: 0.8;    
}

.leaderCornerCard {
  background-color: #f2f2f2;
  position: relative;
  padding: 20px;
  border-radius: 30px;
  height: 100%;
}

.leaderCornerCardHeader {
  background: linear-gradient(to right, #EAF4FA, #BDE4FF);
  position: absolute;
  border: 2px solid white;
  border-radius: 50px;
  padding: 10px;
  min-width: 80%;  
  display: flex;  
  justify-content: center;
  align-content: center;
  align-items: center;  
  top: -40px
  
}
.leaderCornerCardHeader img {
  height: 30px
}

.leaderCornerImg {
  height: 70px;
  width: auto;
  margin: 20px
}

.mediaContainerGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 5px;
  width: 100%;
  justify-content: center;
}

.mediaContainer {
  overflow: hidden;
  transition: transform 0.3s ease;
  background-color: #F9F7F7;
  border-radius: 10px;
  /* position: relative; */
  /* margin: 1px; */
  padding: 5px;
}

.mediaContainer:hover { 
  transform: scale(1.01);
}

.trackingText {
  /* background-color: var(--blue-color);  */
  /* background-color:  rgb(8, 138, 199);  */
  background-color: #007CB4;
  color: white;
  text-align: center;
  padding: 5px;
  min-width: 150px;
  border-radius: 20px;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  
}

.teamSetupCardBackground {
  background-color: #F8F6F7;  
  padding: 16px;
  position: relative;
}

.teamSetupCardImage {
  position: absolute;
  left: -15px;
  top: -15px
}

.teamSetupCardTitle {
  color: rgb(8, 138, 199) !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  margin-bottom: 8px !important;
  padding-left: 40px;
}

.teamSetupCardSubtitle {
  color: black;
  font-size: 0.8rem !important;
  margin-top: 8px !important;
  /* margin-bottom: 8px !important; */
}

.teamSetupCardSectionTitle {
  color: rgb(8, 138, 199) !important;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  margin-top: 16px !important;
  /* margin-bottom: 4px !important; */
}

.teamSetupInput {
  margin-top: 8px !important;
  /* margin-bottom: 6px; */
}

@media only screen and (max-width: 1377px) {
  .sideBar {
    display: none;
  }

  .mainContent {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    margin: 0;
  }

  .searchBarContainer {
    width: 100%;
  }

  .resultscontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    /* width: 100%; */
  }

  .mediaContainerGrid {
    width: 95%;
    margin: 0 2.5%;
}
}

@media only screen and (max-width: 996px) {
  .mainContentBody {
    flex-direction: column;
    gap: 60px;
  }

  .activityContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    width: 100%;
  }

  .resultscontainer {
    width: 100%;
  }
}

@media only screen and (max-width: 850px) {
  .mediaContainerGrid {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-gap: 15px 15px;
      width: 95%;
      margin: 0 2.5%;
  }
}

@media only screen and (max-width: 520px) {

  .activityFilterDropDown,
  .downloadCSVBtn {
    width: 100px;
  }

  .searchBarContainer {
    border-width: 1px;
    border-radius: 30px;
  }

  .searchBarIconContainer {
    background-color: #ffffff00;
  }

  .activityCardsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px 11px;
    width: 100%;
    justify-content: center;
  }

  .imageContainer {
    height: 100px;

  }

  .cardBtn {
    padding: 10px;
  }

  .cardBtnText {
    font-size: 12px;
  }


}

@media only screen and (max-width: 455px) {
  .activityContainerHeader {
    flex-direction: column;
    gap: 15px;
  }

  .activityFilterDropDown,
  .downloadCSVBtn {
    width: 40%;
  }

  .downloadCSVBtnText {
    font-size: 11px;
  }
}